







































































import {Vue, Component, Prop} from 'vue-property-decorator'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'
import notAvailableImage from '@/assets/img/not-available.png'

@Component
export default class NftAssetCard extends Vue {
  @Prop({type: GMNftAsset, required: true}) nftAsset!: GMNftAsset

  get nftCollectionImageUrl(): string {
    if (!this.nftAsset.collectionImageUrl) {
      return notAvailableImage
    }

    return this.nftAsset.collectionImageUrl
  }

  get nftAssetImageUrl(): string {
    if (!this.nftAsset.imageUrl) {
      return notAvailableImage
    }

    return this.nftAsset.imageUrl
  }

  onImageError(event: Event) {
    const target = event.target as HTMLImageElement
    target.src = notAvailableImage
  }
}
