import {BalanceResponse, ShrikeHelper} from '@/helpers/ShrikeHelper'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import {bsNeo3} from '@/libs/bsNeo3'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import {NeoHelper} from './NeoHelper'
import {$} from '@/facade'
import Vue from 'vue'
import {GasCalculatorTargetCollection} from '@/model/collection/GasCalculatorTargetCollection'
import {PriceHistoryCollection} from '@/model/collection/PriceHistoryCollection'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {GasCalculatorTarget} from '@/model/resource/GasCalculatorTarget'
import {SortOption} from '@/types/SortOption'
import {GMNftAsset} from '@/model/resource/GhostMarket/GMNftAsset'
import {GMNftCollection} from '@/model/resource/GhostMarket/GMNftCollection'

type WalletInfo = {
  unclaimedGas: string
  fee: string
  balances: BalanceResponse[]
  tokenAssets: MyWalletTokensTableItem[]
  tokenLastTimeUpdated: string
  nftLastTimeUpdated: string
  nftAssetCollection: GMNftAssetCollection
  nftCollectionCollection: GMNftCollectionCollection
  candidateVoted: GasCalculatorTarget
}

export class MyWalletCacheAssist {
  tokensTableItemsCollection = new TokensTableItemsCollection().noPagination()
  gasCalculatorTargetCollection = new GasCalculatorTargetCollection().noPagination()
  priceHistoryCollection = new PriceHistoryCollection().noPagination()

  connectedAddress = ''

  isRefreshing = false
  isWatchWalletInUse = false

  defaultWallet: WalletInfo = {
    unclaimedGas: '',
    fee: '',
    balances: [],
    tokenAssets: [],
    tokenLastTimeUpdated: '',
    nftLastTimeUpdated: '',
    nftAssetCollection: new GMNftAssetCollection(),
    nftCollectionCollection: new GMNftCollectionCollection(),
    candidateVoted: new GasCalculatorTarget(),
  }

  private cacheWallet: Record<string, WalletInfo> = {}

  constructor(isMobile: boolean) {
    this.currentWallet.nftAssetCollection.limit = isMobile ? 6 : 15
  }

  get gasPriceInDollar() {
    return Number(this.priceHistoryCollection.items[0]?.currentPrice) ?? 0
  }

  get candidateVoted() {
    return this.currentWallet.candidateVoted
  }

  set candidateVoted(candidate: GasCalculatorTarget) {
    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      candidateVoted: candidate,
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }

  get currentWallet(): WalletInfo {
    return this.cacheWallet[this.connectedAddress]
      ? {...this.cacheWallet[this.connectedAddress]}
      : this.defaultWallet
  }

  get balance(): string {
    return this.currentWallet.tokenAssets
      ?.reduce((acc, item) => acc + item.amountInDollar, 0)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })
  }

  get neoBalance(): number {
    const neoToken = this.currentWallet.balances?.find(
      item => item.token.symbol === 'NEO'
    )

    return Number(neoToken?.amount ?? 0)
  }

  setIsWatchWalletInUse(value: boolean) {
    Vue.nextTick(async () => {
      this.isWatchWalletInUse = value
    })
  }

  async refreshData() {
    if (!this.connectedAddress) {
      throw new Error(
        'Undefined address connected. It is impossible to update the data.'
      )
    }
    $.await.init('populateMyWalletAssets')

    Vue.set(this, 'isRefreshing', true)

    try {
      await this.populate()

      await this.waitUntilWalletPopulated(this.connectedAddress)
    } catch (error) {
      console.error('Erro ao atualizar os dados:', error)
    } finally {
      Vue.set(this, 'isRefreshing', false)
      $.await.done('populateMyWalletAssets')
    }
  }

  async populateWallet() {
    $.await.init('populateMyWalletAssets')

    await $.utils.sleep(500)

    const address = $.walletAdapter.n3Address
    if (!address) {
      throw new Error(
        'Undefined address connected. It is impossible to update the data.'
      )
    }

    this.setIsWatchWalletInUse(false)

    if (this.cacheWallet[address]) {
      this.connectedAddress = ''
      Vue.nextTick(async () => {
        this.connectedAddress = address

        await this.waitUntilWalletPopulated(address)
        $.await.done('populateMyWalletAssets')
      })
    } else {
      Vue.set(this.cacheWallet, address, {...this.defaultWallet})

      this.connectedAddress = ''
      Vue.nextTick(async () => {
        this.connectedAddress = address
        await this.populate()

        await this.waitUntilWalletPopulated(address)
        $.await.done('populateMyWalletAssets')
      })
    }
  }

  async populateWatchWallet(address: string) {
    if (!NeoHelper.validateN3Address(address)) {
      $.toast.abort('Invalid N3 Address.')
      return
    }

    $.await.init('populateMyWalletAssets')

    this.setIsWatchWalletInUse(true)

    await $.watchWallet.setAddress(address)

    if (this.cacheWallet[address]) {
      this.connectedAddress = ''
      Vue.nextTick(async () => {
        this.connectedAddress = address

        await this.waitUntilWalletPopulated(address)
        $.await.done('populateMyWalletAssets')
      })
    } else {
      Vue.set(this.cacheWallet, address, {...this.defaultWallet})
      this.connectedAddress = ''
      Vue.nextTick(async () => {
        this.connectedAddress = address
        await this.populate()

        await this.waitUntilWalletPopulated(address)
        $.await.done('populateMyWalletAssets')
      })
    }
  }

  private async waitUntilWalletPopulated(address: string, timeout = 5000) {
    const start = Date.now()

    while (!this.isWalletPopulated(address)) {
      if (Date.now() - start > timeout) {
        break
      }

      await $.utils.sleep(100)
    }
  }

  private isWalletPopulated(address: string): boolean {
    const wallet = this.cacheWallet[address]
    if (!wallet) return false

    return (
      Array.isArray(wallet.balances) &&
      wallet.balances.length > 0 &&
      wallet.candidateVoted !== null &&
      wallet.fee !== '' &&
      Array.isArray(wallet.nftAssetCollection?.items) &&
      wallet.nftAssetCollection.items.length > 0 &&
      Array.isArray(wallet.nftCollectionCollection?.items) &&
      wallet.nftCollectionCollection.items.length > 0 &&
      wallet.nftLastTimeUpdated !== '' &&
      Array.isArray(wallet.tokenAssets) &&
      wallet.tokenAssets.length > 0 &&
      wallet.tokenLastTimeUpdated !== '' &&
      wallet.unclaimedGas !== ''
    )
  }

  private async populate() {
    this.priceHistoryCollection.withoutTotal = true
    this.priceHistoryCollection.symbols = ['GAS']

    const mainPromise: Promise<unknown>[] = [
      this.tokensTableItemsCollection.queryAsPage(),
      this.priceHistoryCollection.queryAsPage(),
    ]
    await Promise.allSettled(mainPromise)

    this.gasCalculatorTargetCollection.withoutTotal = true

    const secondaryPromise: Promise<unknown>[] = [
      this.populateBalance(),
      this.populateUnclaimedGas(),
      this.populateFee(),
      this.gasCalculatorTargetCollection.queryAsPage(),
    ]
    await Promise.allSettled(secondaryPromise)

    this.populateTokens()

    const tertiaryPromise: Promise<unknown>[] = [
      this.populateCandidateVoted(),
      this.populateNft(),
    ]
    await Promise.allSettled(tertiaryPromise)
  }

  private populateTokens() {
    const walletTokenAssets: MyWalletTokensTableItem[] = []

    this.currentWallet.balances.forEach(balance => {
      if (balance.amount === '0') return

      const token = this.tokensTableItemsCollection.items.find(
        token => token.marketInformation?.hash === balance.token.hash
      )

      if (!token) return

      const tokenItem = new MyWalletTokensTableItem()
      tokenItem.imageUrl = token.imageUrl
      tokenItem.symbol = token.symbol
      tokenItem.marketInformation = token.marketInformation
      tokenItem.blockchainVersion = token.blockchainVersion
      tokenItem.amount = Number(balance.amount)

      walletTokenAssets.push(tokenItem)
    })

    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      tokenLastTimeUpdated: this.tokensTableItemsCollection
        .lastTimeUpdatedFormatted,
      tokenAssets: walletTokenAssets,
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }

  private async populateNft() {
    this.currentWallet.nftAssetCollection.ownerAddress = this.connectedAddress
    this.currentWallet.nftAssetCollection.orderBy = 'MintDate'
    this.currentWallet.nftAssetCollection.orderDirection = 'Desc'

    await this.currentWallet.nftAssetCollection.queryAsPage()

    this.currentWallet.nftCollectionCollection.ownerAddress = this.connectedAddress

    await this.currentWallet.nftCollectionCollection.queryAsPage()

    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      nftLastTimeUpdated: new Date().toLocaleString(),
      nftAssetCollection: this.currentWallet.nftAssetCollection,
      nftCollectionCollection: this.currentWallet.nftCollectionCollection,
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }

  private async populateBalance() {
    const walletBalance = await ShrikeHelper.getBalance(this.connectedAddress)

    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      balances: walletBalance,
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }

  private async populateCandidateVoted() {
    const candidatePublicKey = await NeoHelper.getVoterData(
      this.connectedAddress
    )

    let currentCandidateVoted: GasCalculatorTarget | null = null

    const isNoCandidate = candidatePublicKey === ''
    if (isNoCandidate) {
      currentCandidateVoted = this.gasCalculatorTargetCollection
        .sortedCandidates[0]
    } else {
      currentCandidateVoted =
        this.gasCalculatorTargetCollection.sortedCandidates.find(
          candidate => candidatePublicKey === `0${candidate.publicKey}`
        ) ?? null
    }

    if (currentCandidateVoted) {
      const updatedWallet = {
        ...this.cacheWallet[this.connectedAddress],
        candidateVoted: currentCandidateVoted,
      }
      Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
    }
  }

  private async populateUnclaimedGas() {
    const {unclaimed} = await NeoHelper.getUnclaimedGasData(
      this.connectedAddress
    )

    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      unclaimedGas: (unclaimed * 10 ** -8).toFixed(8),
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }

  private async populateFee() {
    const {total} = await NeoHelper.calculateUnclaimedGasFee(
      this.connectedAddress
    )

    const updatedWallet = {
      ...this.cacheWallet[this.connectedAddress],
      fee: total.toString(),
    }
    Vue.set(this.cacheWallet, this.connectedAddress, updatedWallet)
  }
}
