
















import {Vue, Component, Prop} from 'vue-property-decorator'
import {$} from '@/facade'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {InputSelect} from '@simpli/vue-input'
import {SortOption} from '@/types/SortOption'

@Component({
  components: {
    InputSelect,
  },
})
export default class FilterNftAssetCollection extends Vue {
  @Prop({type: GMNftCollectionCollection, required: true})
  nftCollectionCollection!: GMNftCollectionCollection

  options: SortOption[] = []
  optionSelected: SortOption | null = null

  async mounted() {
    this.options = [
      {
        $id: 0,
        field: null,
        asc: false,
        $tag: 'All Collections',
      },

      ...this.nftCollectionCollection.items.map((item, index) => ({
        $id: index + 1,
        field: item.slug || '',
        asc: false,
        $tag: item.name || '',
      })),
    ]
  }

  onFilter(option: SortOption) {
    this.$emit('filter', option.field)
  }
}
