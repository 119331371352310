var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-wallet-my-nfts"},[_c('await',{staticClass:"w-full h-full",attrs:{"name":"populateMyWalletAssets","spinner":"MoonLoader"}},[_c('div',{staticClass:"flex flex-col gap-y-6"},[_c('div',{staticClass:"flex flex-col justify-center items-center md:flex-row gap-6"},[_c('adap-searchfield',{staticClass:"my-wallet-my-nfts__search input",attrs:{"id":"adapSearchFilterDapp","collection":_vm.cacheAssist.currentWallet.nftAssetCollection}}),(!_vm.isLessThanTablet)?_c('filter-nft-asset-collection',{staticClass:"my-wallet-my-nfts__collection-filter",attrs:{"nft-collection-collection":_vm.cacheAssist.currentWallet.nftCollectionCollection},on:{"filter":function (option) { return _vm.cacheAssist.currentWallet.nftAssetCollection.filterCollection(
                option
              ); }}}):_vm._e(),(!_vm.isLessThanTablet && !_vm.isTablet)?_c('filter-nft-asset',{staticClass:"my-wallet-my-nfts__sort-filter",attrs:{"nft-asset-collection":_vm.cacheAssist.currentWallet.nftAssetCollection},on:{"filter":function (option) { return _vm.cacheAssist.currentWallet.nftAssetCollection.sort(option); }}}):_c('div',{staticClass:"flex justify-between items-center w-full gap-4"},[_c('filter-nft-asset',{staticClass:"my-wallet-my-nfts__sort-filter",attrs:{"nft-asset-collection":_vm.cacheAssist.currentWallet.nftAssetCollection},on:{"filter":function (option) { return _vm.cacheAssist.currentWallet.nftAssetCollection.sort(option); }}}),_c('a',{staticClass:"flex items-center relative gap-2 font-semibold",on:{"click":_vm.toggleModal}},[_c('em',{staticClass:"fas fa-sliders-h text-xl"}),_c('label',{staticClass:"text-xl mr-10 cursor-pointer"},[_vm._v(" "+_vm._s(_vm.$translate('app.filter'))+" ")]),_c('span',{staticClass:"top-0 right-0 absolute flex bg-black mr-3 mb-16 px-2 py-1 rounded-full font-semibold text-white text-xs"},[_vm._v(" "+_vm._s(_vm.cacheAssist.currentWallet.nftAssetCollection.collectionSlug ? '1' : '0')+" ")])])],1)],1),_c('hr'),_c('await',{staticClass:"w-full h-full flex flex-col gap-4",attrs:{"name":"filterNfts","spinner":"MoonLoader"}},[(
            _vm.cacheAssist.currentWallet.nftAssetCollection.items &&
              _vm.cacheAssist.currentWallet.nftAssetCollection.items.length > 0
          )?_c('div',{staticClass:"grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6"},_vm._l((_vm.cacheAssist.currentWallet.nftAssetCollection.items),function(item){return _c('nft-asset-card',{key:item.id,attrs:{"nft-asset":item}})}),1):_c('div',{staticClass:"flex items-center-center"},[_c('span',{staticClass:"uppercase text-center text-xl text-regent-gray"},[_vm._v(" "+_vm._s(_vm.$translate('app.noResultFound'))+" ")])]),(_vm.cacheAssist.currentWallet.nftAssetCollection.hasNextPage())?_c('div',{staticClass:"my-wallet-my-nfts__pagination-control"},[_c('button',{staticClass:"my-wallet-my-nfts__pagination-control-button my-wallet-my-nfts__pagination-control-button--first",attrs:{"disabled":_vm.cacheAssist.currentWallet.nftAssetCollection.isOnFirstPage()},on:{"click":function($event){return _vm.cacheAssist.currentWallet.nftAssetCollection.navigateToPage(
                'first'
              )}}},[_vm._v(" "+_vm._s(_vm.$translate('app.first'))+" ")]),_c('button',{staticClass:"my-wallet-my-nfts__pagination-control-button",attrs:{"disabled":!_vm.cacheAssist.currentWallet.nftAssetCollection.hasPreviousPage()},on:{"click":function($event){return _vm.cacheAssist.currentWallet.nftAssetCollection.navigateToPage(
                'previous'
              )}}},[_c('i',{staticClass:"fas fa-angle-left"})]),_c('span',{staticClass:"my-wallet-my-nfts__pagination-control-page"},[_vm._v(" "+_vm._s(_vm.cacheAssist.currentWallet.nftAssetCollection.currentNFTPage)+" ")]),_c('button',{staticClass:"my-wallet-my-nfts__pagination-control-button",attrs:{"disabled":!_vm.cacheAssist.currentWallet.nftAssetCollection.hasNextPage()},on:{"click":function($event){return _vm.cacheAssist.currentWallet.nftAssetCollection.navigateToPage(
                'next'
              )}}},[_c('i',{staticClass:"fas fa-angle-right"})])]):_vm._e()])],1),(
        !_vm.cacheAssist.currentWallet.nftAssetCollection &&
          !_vm.cacheAssist.currentWallet.nftCollectionCollection
      )?_c('div',{staticClass:"flex items-center justify-center p-2"},[_c('span',{staticClass:"uppercase text-center text-xl text-regent-gray"},[_vm._v(" "+_vm._s(_vm.$translate('app.noResultFound'))+" ")])]):_vm._e(),_c('modal',{attrs:{"closable":true,"closeOutside":false,"innerClass":"large"},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('filter-nft-asset-collection-mobile',{staticClass:"my-wallet-my-nfts__collection-filter",attrs:{"nft-collection-collection":_vm.cacheAssist.currentWallet.nftCollectionCollection,"selected-field":_vm.cacheAssist.currentWallet.nftAssetCollection.collectionSlug},on:{"filter":function (option) { return _vm.cacheAssist.currentWallet.nftAssetCollection.filterCollection(
              option
            ); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }