




















import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import {$} from '@/facade'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {InputSelect} from '@simpli/vue-input'
import {SortOption} from '@/types/SortOption'
import {InputCheckbox} from '@simpli/vue-input'

@Component({
  components: {
    InputSelect,
    InputCheckbox,
  },
})
export default class FilterNftAssetCollectionMobile extends Vue {
  @Prop({type: GMNftCollectionCollection, required: true})
  nftCollectionCollection!: GMNftCollectionCollection
  @Prop({type: String, default: null})
  selectedField!: string | null

  options: SortOption[] = []
  optionSelected: SortOption | null = null

  async mounted() {
    this.options = [
      {
        $id: 0,
        field: null,
        asc: false,
        $tag: 'All Collections',
      },

      ...this.nftCollectionCollection.items.map((item, index) => ({
        $id: index + 1,
        field: item.slug || '',
        asc: false,
        $tag: item.name || '',
      })),
    ]
  }

  onSelectOption(field: string | null) {
    this.$emit('filter', field)
    this.$modal.close()
  }
}
