

















import {Vue, Component, Prop} from 'vue-property-decorator'
import {$} from '@/facade'
import {SortOption} from '@/types/SortOption'
import {InputSelect} from '@simpli/vue-input'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'

@Component({
  components: {
    InputSelect,
  },
})
export default class FilterNftAsset extends Vue {
  @Prop({type: GMNftAssetCollection, required: true})
  nftAssetCollection!: GMNftAssetCollection

  optionSelected: SortOption | null = null
  options: SortOption[] = []

  async mounted() {
    this.options = [
      {
        $id: 0,
        field: 'mintDate',
        asc: false,
        $tag: 'Minted, new to old ↓',
      },
      {
        $id: 1,
        field: 'mintDate',
        asc: true,
        $tag: 'Minted, old to new ↑',
      },
    ]
  }

  onFilter(option: SortOption) {
    this.$emit('filter', option.asc)
  }
}
