


































































































































































































































































































































import {Component, Prop, Watch} from 'vue-property-decorator'
import TokenCellRender from '@/components/render/TokenCellRender.vue'
import PercentageProgressRender from '@/components/render/PercentageProgressRender.vue'
import BounceLoader from 'vue-spinner/src/BounceLoader.vue'
import LastPriceUpdated from '@/components/LastPriceUpdated.vue'
import PoweredBy, {PoweredByItem} from '@/components/PoweredBy.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'
import {$} from '@/facade'
import MyWalletProceedButton from '@/components/myWallet/MyWalletProceedButton.vue'
import MyWalletDisclaimerMobile from '@/components/myWallet/MyWalletDisclaimerMobile.vue'

@Component({
  computed: {
    $() {
      return $
    },
  },
  components: {
    MyWalletDisclaimerMobile,
    MyWalletProceedButton,
    PoweredBy,
    LastPriceUpdated,
    PercentageProgressRender,
    TokenCellRender,
    BounceLoader,
  },
})
export default class MyWalletTokensTable extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  openMobileDisclaimer = false
  selectedToken: MyWalletTokensTableItem | null = null

  headers = ['TOKEN', 'BALANCE', 'VALUE', 'PRICE', '24H %', '7D %', '']

  poweredByList = [
    {
      url: 'https://flamingo.finance/',
      alt: 'flamingo icon',
      filename: 'flamingo-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://www.forthewin.network/#/',
      alt: 'ftwicon',
      filename: 'for-the-win-logo.svg',
    } as PoweredByItem,
    {
      url: 'https://neofura-1.gitbook.io/api-docs/',
      alt: 'magnet icon',
      filename: 'magnet-icon.svg',
    } as PoweredByItem,
    {
      url: 'https://carrot-fi.xyz/',
      alt: 'carrot swap icon',
      filename: 'carrot-swap.svg',
    } as PoweredByItem,
  ]

  displayedTokens: MyWalletTokensTableItem[] = []
  moreTokensAvailableToDisplay: MyWalletTokensTableItem[] = []

  get lastTimePriceUpdated() {
    return this.cacheAssist.currentWallet.tokenLastTimeUpdated || ''
  }

  popoverText(token?: MyWalletTokensTableItem) {
    if (this.cacheAssist.isWatchWalletInUse) {
      return this.$translate(
        'components.MyWalletTokensTable.watchWalletDisclaimer'
      )
    }

    const item = token ?? this.selectedToken

    if (item && item.isSwappable) {
      return this.$translate(
        'components.MyWalletTokensTable.temporarilyUnavailable'
      )
    }

    return this.$translate(
      'components.MyWalletTokensTable.unavailableOnFlamingo'
    )
  }

  mounted() {
    this.initScrollListener()
  }

  beforeDestroy() {
    this.removeScrollListener()
  }

  handleScroll() {
    const container = this.$el.querySelector(
      '.table-scroll-container'
    ) as HTMLElement
    const fog = container.querySelector('.fog-overlay') as HTMLElement

    if (!fog) return

    const isAtEnd =
      container.scrollWidth - container.scrollLeft <= container.clientWidth + 1
    fog.style.opacity = isAtEnd ? '0' : '1'
    fog.style.right = `${-container.scrollLeft}px`
  }

  initScrollListener() {
    this.$nextTick(() => {
      const container = this.$el.querySelector('.table-scroll-container')
      if (container) {
        container.addEventListener('scroll', this.handleScroll)
        this.handleScroll()
      } else {
        setTimeout(this.initScrollListener, 100)
      }
    })
  }

  removeScrollListener() {
    const container = this.$el.querySelector('.table-scroll-container')
    if (container) {
      container.removeEventListener('scroll', this.handleScroll)
    }
  }

  @Watch('cacheAssist.currentWallet', {immediate: true, deep: true})
  onTokenAssetsChange() {
    this.displayedTokens = this.cacheAssist.currentWallet.tokenAssets?.slice(
      0,
      5
    )
    this.moreTokensAvailableToDisplay = this.cacheAssist.currentWallet.tokenAssets?.slice(
      5
    )
  }

  isSwapButtonDisabled(item: MyWalletTokensTableItem) {
    return true

    // if (this.cacheAssist.isWatchWalletInUse) {
    //   return true
    // }
    //
    // return !item.isSwappable
  }

  setOpenMobileDisclaimer(token: MyWalletTokensTableItem, val: boolean) {
    this.selectedToken = token
    this.openMobileDisclaimer = val
  }

  loadMore() {
    this.displayedTokens.push(...this.moreTokensAvailableToDisplay.splice(0, 5))
  }

  async onClickSwap(token: MyWalletTokensTableItem) {
    await this.$walletAdapter.executeWithConnectedN3Wallet(() => {
      this.$modal.open('MyWalletSwapModal', token)
    })
  }
}
