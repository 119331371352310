




























































































import {Component, Prop} from 'vue-property-decorator'
import MyWalletTokensTable from '@/components/myWallet/MyWalletTokensTable.vue'
import {MyWalletTokensTableItem} from '@/model/resource/MyWalletTokensTableItem'
import Tooltip from '@/components/tooltip/Tooltip.vue'
import {MixinScreenSize} from '@/components/mixins/MixinScreenSize'
import {FlamingoSwapHelper} from '@/libs/blockchain-services/helpers/FlamingoSwapHelper'
import {DEFAULT_NETWORK} from '@/libs/blockchain-services/constants/BSNeo3Constants'
import MyWalletSwapModal from './MyWalletSwap/MyWalletSwapModal.vue'
import {TokensTableItemsCollection} from '@/model/collection/TokensTableItemsCollection'
import MyWalletMyNfts from '@/components/myWallet/MyWalletMyNfts.vue'
import {GMNftAssetCollection} from '@/model/collection/GhostMarket/GMNftAssetCollection'
import {GMNftCollectionCollection} from '@/model/collection/GhostMarket/GMNftCollectionCollection'
import {SortOption} from '@/types/SortOption'
import {MyWalletCacheAssist} from '@/helpers/MyWalletCacheAssist'

@Component({
  components: {
    MyWalletMyNfts,
    Tooltip,
    MyWalletTokensTable,
    MyWalletSwapModal,
  },
})
export default class MyWalletTokensNftsSection extends MixinScreenSize {
  @Prop({type: MyWalletCacheAssist, required: true})
  cacheAssist!: MyWalletCacheAssist

  swappableTokensSymbol = FlamingoSwapHelper.listSwappableTokensSymbol(
    DEFAULT_NETWORK
  )
  isTokensTableVisible = true
}
